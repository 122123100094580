import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const SslVasco = lazy(() => import('sections/hero/SslVasco.js'));
const PreciosVasco = lazy(() => import('sections/services/PreciosVasco.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const BoxCol2Vasco = lazy(() => import('sections/hero/BoxCol2Vasco.js'));
const Ca = lazy(() => import('sections/hero/Ca.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const EspecSSLVasco = lazy(() => import('sections/services/EspecSSLVasco.js'));
const FaqVasco = lazy(() => import('../../sections/services/FaqVasco')); // Asegúrate de que la ruta sea correcta
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {


    return (
      <div>
        <Helmet>
          <title>Certificados de Servidor Seguro en Colombia | SSL Gratis</title>
          <meta name="description" content="Asegura la protección de tu sitio web con certificados de servidor seguro en Colombia. VASCO Solutions ofrece certificados SSL confiables y con opción gratis." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <SslVasco />
            <PreciosVasco />
            <EspecSSLVasco />
            <BoxCol2Vasco />
            <Ca />
            <SelloSitelock />
            <SeguridadInformatica />
            <FaqVasco />
            <BotonSanGeo />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`